var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column",
      style: _vm.activeOrNot
        ? `border-top:4px solid${_vm.situation.color}; border-border-left: 1px solid #e7e7fa;border-right:1px solid #e7e7fa;box-shadow: none !important;`
        : `border: 1px solid #e7e7fa`,
      on: {
        click: (items) =>
          _vm.fetchFromCard({ prop: "status", value: _vm.situation.name }),
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-space-between",
          staticStyle: { height: "64px" },
        },
        [
          _c("v-card-title", { staticClass: "titlecard titleColor--text" }, [
            _vm._v(_vm._s(_vm.situation.name)),
          ]),
          _c(
            "v-tooltip",
            {
              attrs: { "max-width": "250px", right: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mx-2 mt-4",
                              attrs: { icon: "", elevation: "0" },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "material-symbols-outlined button-size notranslate",
                            },
                            [_vm._v("help")]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v(_vm._s(_vm.situation.tooltipT))])]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column" },
          [
            _c("v-card-title", { staticClass: "titleNumber" }, [
              _vm._v(_vm._s(_vm.situation.total[1])),
            ]),
            _c("v-card-subtitle", { staticClass: "headerClass" }, [
              _vm._v(_vm._s(_vm.situation.percent) + "% do total"),
            ]),
          ],
          1
        ),
        _vm.situation.total
          ? _c(
              "div",
              { staticClass: "d-flex flex-column mt-n1" },
              [
                _c("apexCharts", {
                  staticClass: "apexchart",
                  attrs: {
                    width: "100%",
                    height: "150",
                    options: _vm.chartOptions,
                    series: _vm.situation.total,
                    type: "donut",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }