<template>
  <div
    class="d-flex flex-column"
    :style="
      activeOrNot
        ? `border-top:4px solid${situation.color}; border-border-left: 1px solid #e7e7fa;border-right:1px solid #e7e7fa;box-shadow: none !important;`
        : `border: 1px solid #e7e7fa`
    "
    @click="(items) => fetchFromCard({ prop: 'status', value: situation.name })"
  >
    <div class="d-flex justify-space-between" style="height: 64px">
      <v-card-title class="titlecard titleColor--text">{{ situation.name }}</v-card-title>
      <v-tooltip max-width="250px" right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mx-2 mt-4" v-bind="attrs" v-on="on" elevation="0">
            <span class="material-symbols-outlined button-size notranslate">help</span>
          </v-btn>
        </template>
        <span>{{ situation.tooltipT }}</span>
      </v-tooltip>
    </div>
    <div class="d-flex justify-space-between">
      <div class="d-flex flex-column">
        <v-card-title class="titleNumber">{{ situation.total[1] }}</v-card-title>

        <v-card-subtitle class="headerClass">{{ situation.percent }}% do total</v-card-subtitle>
      </div>
      <div class="d-flex flex-column mt-n1" v-if="situation.total">
        <apexCharts
          class="apexchart"
          width="100%"
          height="150"
          :options="chartOptions"
          :series="situation.total"
          type="donut"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ThemeMixin from '@/mixins/Theme.vue';
import 'dayjs/locale/pt-br';

export default {
  name: 'CardByStatus',
  props: ['situation', 'activeOrNot'],

  components: {
    ApexCharts: () => import('vue-apexcharts'),
    NoData: () => import('../../indicadores/components/NoData.vue'),
  },

  mixins: [ThemeMixin],

  data() {
    return {
      chartOptions: {
        chart: {
          width: 150,
          height: 150,
        },

        dataLabels: {
          enabled: false,
          // formatter: function (value) {
          //   let a = 2070 - Number(value);

          //   return (value - a).toFixed() + ' %';
          // },
        },
        plotOptions: {
          pie: {
            customScale: 0.75,
          },
        },
        tooltip: {
          enabled: false,
        },
        noData: {
          text: undefined,
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#ECE7F5',
            fontSize: '14px',
            fontFamily: undefined,
          },
        },
        legend: {
          show: false,
          position: 'top',
          showForSingleSeries: false,
          showForZeroSeries: true,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
          },
          labels: {
            show: false,
            colors: '#575767',
          },
        },
        colors: ['#e7e7fa', this.situation.color],
      },
    };
  },

  computed: {
    ...mapGetters({
      statusCounters: 'esocial/esocialMain/getStatusCounter',
      loading: 'esocial/esocialMain/getLoading',
    }),
    sit() {
      return this.situation;
    },
  },
  methods: {
    ...mapActions('esocial/esocialMain/', ['fetchFromCard', 'setPagination']),
  },
};
</script>
<style scoped>
.headerClass {
  padding-top: 16px !important;
  font-size: 16px;
  color: '#575767';
  font-weight: 'regular';
  white-space: nowrap;
}
.titleNumber {
  margin-top: 16px;
  font-size: 40px;
  color: '#575767';
  font-weight: 'regular';
}
.titlecard {
  font-size: 20px;
  color: var(--v-primary-base);
  white-space: wrap;
  word-break: normal;
}
.v-tooltip__content {
  white-space: wrap;
  word-break: normal;
  opacity: 1;
}

.titleNumber {
    min-width: 150px;
}

</style>
